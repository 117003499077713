<template>
    <div class="container">
  <div class="">
    
    <h1 class="catalog-page-title text-center my-5">Редактирование профиля</h1>
    <a href="/admin/Главная" class="ms-sm-4 mb-3 d-block" style="text-decoration: underline;" v-if="$store.state.user_info.mail == 'admin@tai'">Перейти в панель администратора</a>
  </div>

  <div class="content-block">
    <div class="row mb-4">
      <div class="col-12 col-lg-6 px-sm-5">
        <div class="userProfile">
          <form  class="userProfile__form" @submit.prevent="updateUser">
            
            <div class="mb-3">
              <label for="email" class="form-label">E-mail</label>
              <input type="text" class="form-control" name="email" id="email" placeholder="E-mail" v-model="$store.state.user_info.mail" disabled>
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">Номер телефона</label>
              <input type="text" class="form-control" name="phone" id="phone" placeholder="Номер телефона" v-model="$store.state.user_info.phone" minlength="10" maxlength="18" required>
            </div>
            <div class="mb-3">
              <label for="fullName" class="form-label">ФИО</label>
              <input type="text" class="form-control" name="fullName" id="fullName" placeholder="ФИО" v-model="$store.state.user_info.name" minlength="2" maxlength="100" required>
            </div>
            <div class="mb-3">
              <button type="submit" class="btn-white">Сохранить данные</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-lg-6 px-sm-5 mt-5 mt-lg-0">
        <div class="changePassword">
          <form @submit.prevent="updatePassword" class="changePassword__form">
          
            <div class="mb-3">
              <label for="oldPassword" class="form-label">Старый пароль</label>
              <input type="password" class="form-control" name="oldPassword" id="oldPassword" v-model="password.old" placeholder="Старый пароль" required>
            </div>
            <div class="mb-3">
              <label for="newPassword" class="form-label">Новый пароль</label>
              <input type="password" class="form-control" name="newPassword" id="newPassword" v-model="password.new" placeholder="Новый пароль" minlength="5" maxlength="30" required>
            </div>
            <div class="mb-3">
              <label for="confirmNewPassword" class="form-label">Подтвердите новый пароль</label>
              <input type="password" class="form-control" name="confirmNewPassword" v-model="password.repeat" id="confirmNewPassword" placeholder="Подтвердите новый пароль" required>
            </div>
            <div class="mb-3">
              <button type="submit" class="btn-white">Изменить пароль</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 class="catalog-page-title text-center mt-5">Избранное Недвижимость</h2>
        <div class="row" v-if="items.length == 0" style="min-height: 200px;">
            <p class="text-center my-auto">Нет объектов в избранном</p>
        </div>

                    <div class="catalog mt-5" v-else>
                        <div class="row mb-5">
                            <div class="col-lg-3 col-12 mb-5" v-for="(item, index) in items" :key="index">
                              <a :href="'/items/'+item.id" class="catalog-item" v-if="item!==null">
            <div :id="'carouselExampleIndicators'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
              <div class="carousel-indicators">
                  <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicators'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item item-preview" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                  <img v-lazy="'/api/images_mini/'+img" class="d-block w-100" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>

              <div style="position: absolute; top: 12px; left: 12px; z-index: 10; color: #fff; opacity: 0.7; text-shadow:  1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000; font-size: 0.7em;">{{ item.number }}</div>

              <a v-if="$store.state.user == false" class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" onclick="alert('Доступно только авторизированным пользователям'); return false"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                <div v-if="$store.state.user == true">
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-if="$store.state.user_info.favourites.includes(item.id)" @click.prevent="removeFromFavourites(item.id)"><i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-else @click.prevent="addToFavourites(item.id)"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                </div>

           </div>

            <div class="catalog-item-description">
            
            <span class="catalog-params">
              <div>
                <i class="bi bi-moon-stars-fill"></i> {{ item.bedrooms }} спал. &nbsp; <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" style="width: 1.2em; fill: currentcolor;"><path d="M7 1a3 3 0 0 0-3 2.82V31h2V4a1 1 0 0 1 .88-1H18a1 1 0 0 1 1 .88V5h-5a1 1 0 0 0-1 .88V9h-3v2h19V9h-2V6a1 1 0 0 0-.88-1H21V4a3 3 0 0 0-2.82-3H7zm13 28a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM15 7h10v2H15V7z"></path></svg> {{ item.bathrooms }} сануз. &nbsp; <i class="bi bi-tsunami"></i> {{ item.beach }}м
              </div>
            </span>
            <p style="min-height: 2lh; font-weight: 700;" class="mb-0">{{ item.title }}</p>
            <p class="mb-1 mt-2">Район: {{ item.place }}</p>
            <span class="catalog-price" v-if="item.type == 'Аренда'">от {{ Number(item.price).toLocaleString('ru-RU') }} Thb в месяц</span>
            <span class="catalog-price" v-if="item.type == 'Покупка'">{{ Number(item.price).toLocaleString('ru-RU') }} Thb</span>
            <p class="text-success d-flex" v-if="item.status == 'Свободен' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>
            <p class="text-danger d-flex" v-if="item.status == 'Занят' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>
            <p class="text-primary d-flex" v-if="item.status == 'Под запрос' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>
            
            </div>
          </a>
                            </div>

                        </div>
                        
                    </div>

    <h2 class="catalog-page-title text-center mt-5">Избранное Транспорт</h2>
        <div class="row" v-if="items_moto.length == 0" style="min-height: 200px;">
            <p class="text-center my-auto">Нет объектов в избранном</p>
        </div>

                    <div class="catalog mt-5" v-else>
                        <div class="row mb-5">
                            <div class="col-lg-3 col-12 mb-5" v-for="(item, index) in items_moto" :key="index">
                              <a :href="'/moto/'+item.id" class="catalog-item">
            <div :id="'carouselExampleIndicators'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
              <div class="carousel-indicators">
                  <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicators'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item item-preview" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                  <img v-lazy="'/api/images_mini/'+img" class="d-block w-100" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>

              <div style="position: absolute; top: 10px; left: 12px; z-index: 10; color: #fff; opacity: 0.7; text-shadow:  1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000; font-size: 0.7em;">{{ item.number }}</div>

              <a v-if="$store.state.user == false" class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" onclick="alert('Доступно только авторизированным пользователям'); return false"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                <div v-if="$store.state.user == true">
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-if="$store.state.user_info.favouritesMoto.includes(item.id)" @click.prevent="removeFromFavourites(item.id, moto)"><i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-else @click.prevent="addToFavourites(item.id, 'moto')"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                </div>

           </div>

            <div class="catalog-item-description">
            
            <span class="catalog-params">
            </span>
            <p style="font-weight: 700;" class="mb-1 mt-2">{{ item.mark }} {{ item.model }}</p>
            <p class="my-1">{{ item.year }} года выпуска</p>
            <p class="my-1">Пробег: {{ Number(item.probeg).toLocaleString('ru-RU') }} км</p>
            <p style="font-weight: 700;" class="mb-0 mt-1">Стоимость: {{ Number(item.priceForMonth).toLocaleString('ru-RU') }} Thb в месяц</p>
            </div>
          </a>
                            </div>

                        </div>
                        
                    </div>
                  </div>
                </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default{
  data(){
    return{
      password: {
      },
      items: [],
      items_moto: []
    }
    
  },
  methods: {
    updateUser(){
      let info = {
        action: 'update_info',
        id: this.$store.state.user_info.id,
        name: this.$store.state.user_info.name, 
        phone: this.$store.state.user_info.phone
      }
      axios.post('https://samui31.com/api/update_profile.php', info).then((response)=>{
        if(response.data == 1 && response.status == 200){
          this.$store.commit('updateUser', {name: this.$store.state.user_info.name, phone: this.$store.state.user_info.phone})
          alert('Успешно!');
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
      
    },
    updatePassword(){
      if(this.password.old == this.$store.state.user_info.password){
        if(this.password.new == this.password.repeat){
          axios.post('https://samui31.com/api/update_profile.php', {action: 'update_password', id: this.$store.state.user_info.id, password: this.password.new})
          .then((response)=>{
            if(response.data == 1 && response.status == 200){
              this.$store.commit('updatePassword', this.password.new);
              this.password = {}
              alert('Успешно!');
            }
            else{
              alert('Произошла ошибка! Попробуйте позднее');
            }
          })
        }
        else{
          alert('Пароли не совпадают')
        }
      }
      else{
        alert('Неверный пароль')
      }

    },
    loadFavouritesItems(){
        axios.post('https://samui31.com/api/favourites.php', {action: 'get_items', user_id: this.$store.state.user_info.id}).then((response)=>{
           this.items = response.data
           console.log(response)
        })
        axios.post('https://samui31.com/api/favourites.php', {action: 'get_items', user_id: this.$store.state.user_info.id, type: 'moto'}).then((response)=>{
           this.items_moto = response.data
           console.log(response)
        })
    },
    addToFavourites(id, type){
            axios.post('https://samui31.com/api/favourites.php', {action: 'add', user_id: this.$store.state.user_info.id, item_id: id, type: type}).then(()=>{
              this.loadFavouritesItems()
            })
    },
    removeFromFavourites(id, type){
        axios.post('https://samui31.com/api/favourites.php', {action: 'delete', user_id: this.$store.state.user_info.id, item_id: id, type: type}).then(()=>{
            this.loadFavouritesItems()
        })
    }
  },
  created(){
    this.loadFavouritesItems()
  }
}
</script>