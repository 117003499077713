<template>
    <form @submit.prevent="addReview()" id="reviewForm" class="catalog-filter-block d-flex flex-column col-lg-6 col-12 mb-5 mx-auto" style="background-color: #eee; margin-top: 50px;">
                        <h1 class="catalog-page-title mb-5">Оставить отзыв</h1>

                        <p class="mb-1">Ваше имя</p>
                        <input type="text" class="form-control mb-4" v-model="review.name" placeholder="Введите имя" required>
                        <p class="mb-1">Ваш телеграм</p>
                        <input type="text" class="form-control mb-4" v-model="review.tg" placeholder="Введите TG">
                        <p class="mb-1">Ваш номер телефона</p>
                        <input type="text" class="form-control mb-4" v-model="review.phone" placeholder="Введите номер телефона">
                        <p class="mb-1">Описание</p>
                        <textarea rows="5" class="form-control mb-4" v-model="review.review" placeholder="Введите отзыв" required></textarea>
                        <button class="btn-white ms-auto">Отправить</button>
                        </form>
</template>

<script>
import axios from 'axios'

export default{
    data(){
        return{
            review: {
                tg: '',
                phone: ''
            }
        }
    },
    methods: {
        addReview(){
            axios.post('https://samui31.com/api/add_review.php', this.review).then((response)=>{
                if(response.status == 200){
                    alert('Успешно')
                    window.location.reload()
                }
                
            })
        }
    }
}
</script>