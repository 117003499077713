<template>
    <div class="authBg">
        <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-lg-5">
        <div class="card">
          <div class="card-header pt-4 pb-4 text-center bg-primary"><a href="/" class="card-header__logo"><span>ESTATETAI</span></a></div>
          <div class="card-body p-4">
            <div class="text-center w-100 m-auto">
              <h3 class="text-dark-50 text-center mt-0 fw-bold">Забыли пароль?</h3>
              <p class="text-muted mb-4">Введите свой email, указанный при регистрации. Вышлем ссылку для сброса пароля.</p>
            </div>
            <form action="/api/v1/user/forgot-password" method="post" id="forgotPassword">
              <div class="mb-3">
                <input class="form-control" type="email" id="emailaddress" name="email" placeholder="Введите email аккаунта" required="">
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-primary" type="submit"> Восстановить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted"><a href="/auth" class="text-muted ms-1"><b>Авторизоваться</b></a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
</template>