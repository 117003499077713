<template>
  <menu-vue />
  <router-view/>
  <footer-vue />
</template>

<style lang="scss">
@import url('./assets/style.scss');
</style>

<script>
import MenuVue from './components/MenuVue.vue'
import FooterVue from './components/FooterVue.vue'

export default{
  components: {
    MenuVue, FooterVue
  }
}
</script>