<template>
    <footer style="background: #578bc1" id="contacts">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12 text-center text-lg-start d-flex flex-column justify-content-start mb-5">
                    <a href="/" class="mb-3"><img :src="'https://samui31.com/api/logo/logo-4.png?'+ new Date().getTime()" style="max-width: 120px; height: auto;" alt=""></a>
                    <a href="https://t.me/arendasamui" target="_blank" class="d-block mt-2" style="color: #fff !important;"><i class="bi bi-telegram me-1"></i>@arendasamui</a>
                    <a href="https://t.me/samui_31" target="_blank" class="d-block mt-2"  style="color: #fff !important;"><i class="bi bi-telegram me-1"></i>@samui_31</a>
                    <a href="mailto:welcome@samui31.com" target="_blank" class="d-block mt-2"  style="color: #fff !important;"><i class="bi bi-envelope me-1"></i>welcome@samui31.com</a>
                    <a href="https://samui31.com/Policy.pdf" target="_blank" class="d-block mt-2" style="opacity: 0.8; color: #fff !important;">Политика конфиденциальности</a>
                </div>
                <div class="col-lg-6 col-12 mt-lg-0 mt-5">
                    <!--<a href="#"><img src="../assets/logo-2.png" class="w-25 mx-auto mb-3 d-block" alt=""></a>
                    <h3 class="text-center mb-3">Навигация</h3>
                    <a href="#" class="d-block text-center mt-2" style="opacity: 0.8;">Аренда</a>
                    <a href="#" class="d-block text-center mt-2" style="opacity: 0.8;">Покупка</a>
                    <a href="#" class="d-block text-center mt-2" style="opacity: 0.8;">Сдадим за вас</a>
                    <a href="#" class="d-block text-center mt-2" style="opacity: 0.8;">Отзывы</a>
                    <a href="#" class="d-block text-center mt-2" style="opacity: 0.8;">Личный кабинет</a>-->
                </div>
                <div class="col-lg-3 col-12 ps-lg-5 px-5 pe-lg-0 d-flex flex-column mt-lg-0 mt-5">
                <h3 class="mb-2 text-center" style="color: #fff !important;">Обратная связь</h3>
                    <input type="text" class="form-control form-control-sm mt-2 py-2" v-model="offer.name" placeholder="Введите имя" required>
                    <input type="text" class="form-control form-control-sm mt-2 py-2" v-model="offer.phone" placeholder="Введите телефон" required>
                    <textarea rows="3" class="form-control form-control-sm mt-2 py-2" v-model="offer.item" placeholder="Сообщение" required></textarea>
                    <button class="btn-white btn-white-sm mt-2 mx-auto w-100" @click="sendOffer()" style="color: #000 !important;">Отправить</button>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from 'axios'

export default{
    data(){
        return{
            offer: {
                type: 'Обратная связь',
                mail: 'Нет'
            },
        }
    },
    methods: {
        sendOffer(){
            axios.post('https://samui31.com/api/sendOffer.php', this.offer).then(()=>{
                alert('Заявка отправлена')
                window.location.reload()
            })
        }
    }
}
</script>