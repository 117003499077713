<template>
    <div class="container px-lg-0">
        <nav class="navbar navbar-expand-lg" style="background-color: #fff; z-index: 100;">
        <div class="container-fluid px-lg-0 px-3">
            <a class="navbar-brand pt-2" href="/"><img :src="'https://samui31.com/api/logo/logo-3.png?'+ new Date().getTime()" style="max-width: 120px; height: auto;" alt=""></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent" style="background-color: #fff; z-index: 100;">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                <li class="nav-item mx-3">
                    <a class="nav-link" href="/rent">Аренда</a>
                </li>
                <li class="nav-item mx-3">
                    <a class="nav-link" href="/buy">Продажа</a>
                </li>
                <!--<li class="nav-item mx-3">
                    <a class="nav-link" href="/#offer">Сдадим за вас</a>
                </li>-->
                <li class="nav-item mx-3">
                    <a class="nav-link" href="/moto">Транспорт</a>
                </li>
                <li class="nav-item mx-3">
                    <a class="nav-link" href="/news">Блог</a>
                </li>
                <li class="nav-item mx-3">
                    <a class="nav-link" href="/#reviews">Отзывы</a>
                </li>
                <li class="nav-item mx-3">
                    <a class="nav-link" href="#contacts">Контакты</a>
                </li>
                <li class="nav-item mx-3 d-block d-lg-none" v-if="$store.state.user==false">
                    <a href="/auth" class="nav-link pe-0">Вход</a><a href="/reg" class="nav-link ps-0">Регистрация</a>
                </li>
                <li class="nav-item mx-3 d-block d-lg-none" v-else>
                    <a href="/lk" class="nav-link pe-0">Личный кабинет</a><a href="/" @click="$store.commit('exitUser')" class="nav-link ps-0">Выйти</a>
                </li>
            </ul>
            <div class="menu-lk-btn d-lg-flex d-none" data-bs-toggle="collapse" data-bs-target="#menu-lk-btn-collapse" aria-expanded="false" aria-controls="menu-lk-btn-collapse">
                <!--<img src="../assets/burger_menu_icon.png" alt="">-->
                <img src="../assets/avatar_icon.png" alt="">
            </div>
            <div class="text-center text-lg-start collapse" id="menu-lk-btn-collapse" style="position: absolute; top: 70px; right: 15px;" v-click-outside="hideMenuLk">
                <!--<li class="nav-item my-auto">
                    <a href="https://t.me/samui_31" target="_blank" class="nav-link me-1 d-flex" style="font-size: 1.1em;"><i class="bi bi-telegram"></i><b class="ms-1 d-block" style="margin-top: -1px;">@samui_31</b></a>
                </li>-->
                <div class=" my-auto mx-lg-0 mx-auto card card-body" v-if="$store.state.user==false">
                    <a href="/auth" class="nav-link pe-0 mb-2">Вход</a><span class="d-block nav-link mx-lg-0 mx-3"></span><a href="/reg" class="nav-link ps-0">Регистрация</a>
                </div>
                <div class=" my-auto mx-lg-0 mx-auto card card-body" v-else>
                    <a href="/lk" class="nav-link mb-2 pe-0">Личный кабинет</a><span class="d-block nav-link mx-lg-0 mx-3"></span><a href="/" @click="$store.commit('exitUser')" class="nav-link ps-0">Выйти</a>
                </div>
            </div>

            </div>

            

        </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    methods: {
        loadFavourites(){
            if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id}).then((response)=>{
                    this.$store.commit('authFavourites', response.data)
                    console.log(this.$store.state.user_info.favourites)
                })
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: 'moto'}).then((response)=>{
                    this.$store.commit('authFavouritesMoto', response.data)
                    console.log(this.$store.state.user_info.favouritesMoto)
                })
            }
            
        },

        hideMenuLk(){
            function hasClass(el, className)
            {
                if (el.classList)
                    return el.classList.contains(className);
                return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
            }
            if(document.querySelector('#menu-lk-btn-collapse')) if(hasClass(document.querySelector('#menu-lk-btn-collapse'), 'show')) document.querySelector('#menu-lk-btn-collapse').classList.remove('show')
            
        }
    },
    created(){
        this.loadFavourites() 
    }
}
</script>