<template>
    <div class="modal fade" id="offerModal" tabindex="-1" aria-labelledby="offerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="offerModalLabel">Оставить заявку</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex">
          <form class="w-75 mx-auto my-2">
            <input type="text" class="form-control mt-3" v-model="offer.name" placeholder="Введите ФИО" required>
            <input type="text" class="form-control mt-3" v-model="offer.phone" placeholder="Введите номер телефона" required>
            <input type="text" class="form-control mt-3" v-model="offer.mail" placeholder="Введите E-mail" required>
          </form>
        </div>
        <div class="modal-footer">
            <button class="btn-white" @click="sendOffer">Отправить</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade px-0" :id="'openBidImg'+id" tabindex="-1" aria-labelledby="openBidImgLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered" style="height: fit-content;">
      <div class="modal-content" style="background: none;">
        <div :id="'itemSliderModalBid'+id" class="carousel slide item-slider" data-bs-touch="true" data-bs-interval="false">

          <div class="carousel-inner">
            <div class="carousel-item" :class="{'active': index === openImgSrc}" v-for="(img, index) in JSON.parse(itemInfo.images)" :key="index">
              <img v-lazy="'/api/images/'+img" class="d-block w-100" style="object-fit: contain; height: 100vh; max-width: 100vw; cursor: pointer;" data-bs-dismiss="modal">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderModalBid'+id"  data-bs-slide="prev" >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Предыдущий</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderModalBid'+id" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Следующий</span>
          </button>
          </div>

      </div>
    </div>
  </div>
    <div>
    <div class="item-container container">
        
        <div class="d-lg-flex justify-content-between pt-4 mb-2">
            <h1 class="catalog-page-title" style="font-weight: 600; ">{{ itemInfo.title }}</h1>
            <div class="mt-auto">
                <a v-if="$store.state.user == false" class="d-flex"><span class="d-block me-1 my-auto" style="opacity: 0.7;" onclick="alert('Доступно только авторизированным пользователям')">Добавить в избранное</span> <i class="bi bi-heart" style="opacity: 0.7; color: #000; font-size: 1.2em;"></i></a>
                <a v-if="$store.state.user == true" style="opacity: 0.7;">
                    <a v-if="$store.state.user_info.favourites.includes(itemInfo.id)" @click="removeFromFavourites(itemInfo.id)" style="color: #ff033e; display: flex;"><span class="d-block me-1 my-auto">Убрать из избранного</span> <i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                    <a v-else  @click="addToFavourites(itemInfo.id)" class="d-flex"><span class="d-block me-1 my-auto">Добавить в избранное</span> <i class="bi bi-heart" style="opacity: 0.7; color: #000; font-size: 1.2em;"></i></a>
                </a>
            </div>
        </div>

        <div class="item-images">
            <div class="row">
                <div class="col-6 px-1">
                    <img v-lazy="'/api/images/'+JSON.parse(itemInfo.images)[0]" class="d-block w-100" @click="openImgGetSrc(0)" data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer; border-top-left-radius: 15px; border-bottom-left-radius: 15px;">
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-6 px-1 py-1 pt-0">
                            <img v-if="JSON.parse(itemInfo.images)[1]" v-lazy="'/api/images/'+JSON.parse(itemInfo.images)[1]" class="d-block w-100" @click="openImgGetSrc(1)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer;">
                        </div>
                        <div class="col-6 px-1 py-1 pt-0">
                            <img v-if="JSON.parse(itemInfo.images)[2]" v-lazy="'/api/images/'+JSON.parse(itemInfo.images)[2]" class="d-block w-100" @click="openImgGetSrc(2)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer; border-top-right-radius: 15px;">
                        </div>
                        <div class="col-6 px-1 py-1">
                            <img v-if="JSON.parse(itemInfo.images)[3]" v-lazy="'/api/images/'+JSON.parse(itemInfo.images)[3]" class="d-block w-100" @click="openImgGetSrc(3)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer;">
                        </div>
                        <div class="col-6 px-1 py-1">
                            <img v-if="JSON.parse(itemInfo.images)[4]" v-lazy="'/api/images/'+JSON.parse(itemInfo.images)[4]" class="d-block w-100" @click="openImgGetSrc(4)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer; border-bottom-right-radius: 15px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex mt-2">
            <a data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" class="btn-white btn-white-sm ms-auto" style="padding-top: 3px !important; padding-bottom: 3px !important;" @click="openImgGetSrc(0)">Посмотреть все фото</a>
        </div>
        
        <!--<div id="carouselExampleIndicators" class="carousel slide" data-bs-touch="true" data-bs-interval="false">
        <div class="carousel-indicators">
            <button type="button" v-for="(img, index) in JSON.parse(itemInfo.images)" :key = "index" :class='{active: index == 0}' data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" aria-current="true"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item" v-for="(img, index) in JSON.parse(itemInfo.images)" :key = "index" :class='{active: index == 0}'>
            <img v-lazy="'/api/images/'+img" class="d-block w-100" alt="..." @click="openImgGetSrc(index)" data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer;">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
        </div>-->

            
            
    </div>

    <div class="item-description">
    <div class="container">
        <div class="row text-center text-lg-start">

            <div class="col-lg-6 col-12">
                <!--<h1 class="catalog-page-title mb-0" style="font-weight: 700;">{{ itemInfo.title }}</h1>-->
                <hr class="mt-0">
                <ul class="mt-0">
                    <li class="d-flex mb-2">
                        <img src="../assets/location.png" class="item-services-icon" alt=""><b> {{ itemInfo.place }}</b>
                    </li>

                    <div class="d-lg-flex">
                        <li class="d-flex me-lg-5 mb-2">
                        <img src="../assets/bedrooms.png" class="item-services-icon" alt="">
                        <b>Спальни:  {{ itemInfo.bedrooms }} </b></li>
                        <li class="d-flex me-lg-5 mb-2">
                        <img src="../assets/bathrooms.png" class="item-services-icon" alt="">
                        <b>Санузлы: {{ itemInfo.bathrooms }}</b></li>
                        <li class="d-flex mb-2">
                        <img src="../assets/size.png" class="item-services-icon" alt="">
                        <b> Площадь: {{ itemInfo.size }}м2</b></li>
                    </div>
                    
                    <li class="d-flex mb-2">
                        <img src="../assets/beach.png" class="item-services-icon" alt="">
                        <b>Расстояние до пляжа: {{ itemInfo.beach }}м</b></li>
                    <li class="d-flex mb-2">
                        <img src="../assets/shop.png" class="item-services-icon" alt="">
                        <b>Расстояние до магазина: {{ itemInfo.shop }}м</b></li>
                    
                </ul>
                <hr>
                <p class="mb-3 mt-2 text-start" style="white-space: pre-wrap;">{{ itemInfo.description }}</p>
                <hr>
                <h1 class="catalog-page-title mt-0" style="font-weight: 700; font-size: 22px;">Удобства:</h1>
                <ul class="row item-services-list text-start">
                    <li :class="{'active-service': itemInfo.pool == '1', 'disabled-service': itemInfo.pool == '0'}" class="col-6 d-flex"><img src="../assets/pool.png" class="item-services-icon" alt="">Бассейн</li>
                    <li :class="{'active-service': itemInfo.gym == '1', 'disabled-service': itemInfo.gym == '0'}" class="col-6 d-flex"><img src="../assets/gym.png" class="item-services-icon" alt="">Спортзал</li>
                    <li :class="{'active-service': itemInfo.cond == '1', 'disabled-service': itemInfo.cond == '0'}" class="col-6 d-flex"><img src="../assets/cond.png" class="item-services-icon" alt="">Кондиционер</li>
                    <li :class="{'active-service': itemInfo.tv == '1', 'disabled-service': itemInfo.tv == '0'}" class="col-6 d-flex"><img src="../assets/tv.png" class="item-services-icon" alt="">Телевизор</li>
                    <li :class="{'active-service': itemInfo.wm == '1', 'disabled-service': itemInfo.wm == '0'}" class="col-6 d-flex"><img src="../assets/wm.png" class="item-services-icon" alt="">Стиральная машина</li>
                    <li :class="{'active-service': itemInfo.dw == '1', 'disabled-service': itemInfo.dw == '0'}" class="col-6 d-flex"><img src="../assets/wm.png" class="item-services-icon" alt="">Посудомоечная машина</li>
                    <li :class="{'active-service': itemInfo.fridge == '1', 'disabled-service': itemInfo.fridge == '0'}" class="col-6 d-flex"><img src="../assets/fridge.png" class="item-services-icon" alt="">Холодильник</li>
                    <li :class="{'active-service': itemInfo.internet == '1', 'disabled-service': itemInfo.internet == '0'}" class="col-6 d-flex"><img src="../assets/internet.png" class="item-services-icon" alt="">Вай-Фай, {{ itemInfo.internetSpeed }}мб/с</li>
                    <li :class="{'active-service': itemInfo.plate == '1', 'disabled-service': itemInfo.plate == '0'}" class="col-6 d-flex"><img src="../assets/plate.png" class="item-services-icon" alt="">Плита</li>
                    <li :class="{'active-service': itemInfo.oven == '1', 'disabled-service': itemInfo.oven == '0'}" class="col-6 d-flex"><img src="../assets/oven.png" class="item-services-icon" alt="">Духовка</li>
                    <li :class="{'active-service': itemInfo.hood == '1', 'disabled-service': itemInfo.hood == '0'}" class="col-6 d-flex"><img src="../assets/hood.png" class="item-services-icon" alt="">Вытяжка</li>
                    <li :class="{'active-service': itemInfo.micro == '1', 'disabled-service': itemInfo.micro == '0'}" class="col-6 d-flex"><img src="../assets/micro.png" class="item-services-icon" alt="">Микроволновка</li>
                    <li :class="{'active-service': itemInfo.kettle == '1', 'disabled-service': itemInfo.kettle == '0'}" class="col-6 d-flex"><img src="../assets/kettle.png" class="item-services-icon" alt="">Чайник</li>
                    
                    <li :class="{'active-service': itemInfo.coffee == '1', 'disabled-service': itemInfo.coffee == '0'}" class="col-6 d-flex"><img src="../assets/coffee.png" class="item-services-icon" alt="">Кофемашина</li>
                    <li :class="{'active-service': itemInfo.toster == '1', 'disabled-service': itemInfo.toster == '0'}" class="col-6 d-flex"><img src="../assets/toaster.png" class="item-services-icon" alt="">Тостер</li>
                    <li :class="{'active-service': itemInfo.multi == '1', 'disabled-service': itemInfo.multi == '0'}" class="col-6 d-flex"><img src="../assets/multi.png" class="item-services-icon" alt="">Мультиварка</li>
                    <li :class="{'active-service': itemInfo.blender == '1', 'disabled-service': itemInfo.blender == '0'}" class="col-6 d-flex"><img src="../assets/blender.png" class="item-services-icon" alt="">Блендер</li>
                </ul>
                <hr>
                <h1 class="catalog-page-title mt-0" style="font-weight: 700;  font-size: 22px;" v-if="itemInfo.type == 'Аренда' ">Дополнительная информация:</h1>
                <ul  v-if="itemInfo.type == 'Аренда' ">
                    <li class="active-service d-flex" v-if="itemInfo.animals == 1"><img src="../assets/animals.png" class="item-services-icon" alt="">Питомцы: разрешено</li>
                    <li class="active-service d-flex" v-if="itemInfo.animals == 0"><img src="../assets/animals.png" class="item-services-icon" alt="">Питомцы: запрещено</li>
                    <li class="active-service d-flex" v-if="itemInfo.animals == 2"><img src="../assets/animals.png" class="item-services-icon" alt="">Питомцы: обсуждаются</li>
                </ul>
                <hr v-if="itemInfo.type == 'Аренда' ">
                <h1 class="catalog-page-title mt-0" style="font-weight: 700;  font-size: 22px; ">Ценовое предложение:</h1>
                <ul>
                    <li class="mb-1 d-flex" v-if="itemInfo.type=='Аренда'"><img src="../assets/price.png" class="item-services-icon" alt=""><b>Стоимость за месяц аренды: от {{ Number(itemInfo.price).toLocaleString('ru-RU') }} бат</b></li>
                    <li class="mb-1 d-flex" v-else><img src="../assets/price.png" class="item-services-icon" alt=""><b>Стоимость: от {{ Number(itemInfo.price).toLocaleString('ru-RU') }} бат</b></li>
                    <li class="mb-1 d-flex" v-if="itemInfo.type=='Аренда'"><img src="../assets/deposit.png" class="item-services-icon" alt=""><b>Депозит: от {{ Number(itemInfo.deposit).toLocaleString('ru-RU') }} бат</b></li>
                    <li class="mb-1 d-flex"><img src="../assets/energy.png" class="item-services-icon" alt=""><b>Электричество: {{Number(itemInfo.energy).toLocaleString('ru-RU') }} бат за 1кВт </b></li>
                    <li class="mb-1 d-flex">
                        <img src="../assets/water.png" class="item-services-icon" alt="">
                        <b>Вода: {{ Number(itemInfo.water).toLocaleString('ru-RU') }} бат за &nbsp;</b>
                        <b v-if="itemInfo.waterType == 0">месяц</b>
                        <b v-if="itemInfo.waterType == 1">человека</b>
                        <b v-if="itemInfo.waterType == 2">за 1 куб</b>
                    </li>
                    <li class="mb-1 d-flex" v-if="itemInfo.type == 'Аренда'"><img src="../assets/internet_price.png" class="item-services-icon" alt=""><b>Интернет: <span v-if="Number(itemInfo.internetPrice) == 0">Бесплатно</span><span v-else>{{ Number(itemInfo.internetPrice).toLocaleString('ru-RU') }} бат</span></b></li>
                    <li class="mb-1 d-flex" v-if="itemInfo.type == 'Аренда' "><img src="../assets/cleaning.png" class="item-services-icon" alt="" ><b>Уборка перед выездом: {{ Number(itemInfo.cleaning).toLocaleString('ru-RU') }} бат</b></li>
                    <li class="mb-2 mt-1 d-flex"><img src="../assets/commission.png" class="item-services-icon" alt=""><b>Комиссия агента: {{ Number(itemInfo.commission).toLocaleString('ru-RU')  }} бат</b></li>
                </ul>

                <hr>
                <h1 class="catalog-page-title mt-0" style="font-weight: 700;  font-size: 22px;" v-if="itemInfo.type == 'Аренда' ">Контакты для бронирования:</h1>
                <h1 class="catalog-page-title mt-0" style="font-weight: 700;  font-size: 22px;" v-else>Контакты агента:</h1>
                <li class="mb-2 d-flex"><img src="../assets/tg_icon.png" class="item-services-icon" alt="">
                    <a v-if="itemInfo.contacts.slice(0,1) == '@'" :href="'https://t.me/'+itemInfo.contacts.slice(1)" target="_blank"><b>{{itemInfo.contacts}}</b></a>
                    <a v-if="itemInfo.contacts.slice(0,1) == '+'" :href="'tel:+'+itemInfo.contacts.replace('/[^0-9]/g', '')" target="_blank"><b>{{itemInfo.contacts}}</b></a>
                    <b v-if="itemInfo.contacts.slice(0,1) !== '@' && itemInfo.contacts.slice(0,1) !== '+'">{{itemInfo.contacts}}</b>
                </li>
            </div>

            <div class="col-lg-6 col-12">
                <div class="mb-3" style="height: 400px;" v-html="itemInfo['video']"></div>
                <img :src="'https://samui31.com/api/images/' + itemInfo['map']" class="w-100" alt="">
            </div>

            <div class="item-price-block my-5">
                    
                </div>
            

            <!--<div class="col-12">
                <h2 class="catalog-page-title">Смотрите также</h2>

                <div class="catalog mt-5">
                        <div class="row mb-5">
                            <div class="col-lg-4 col-12 mb-5" v-for="item in itemInfo.analog_items" :key="item.id">
                            <a :href="'/items/'+item.id" class="catalog-item">

                                <div :id="'carouselExampleIndicators'+item.id" class="carousel slide">
                                <div class="carousel-inner">
                                    <div class="carousel-item" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                                    <img v-lazy="'/api/images_mini/'+img" class="d-block w-100" alt="...">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                                </div>

                                <div class="catalog-item-description">
                                <span class="catalog-price" v-if="$props.type == 'Аренда'"><b>{{ Number(item.price).toLocaleString('ru-RU') }} ฿</b>/мес.</span>
                                <span class="catalog-price" v-if="$props.type == 'Покупка'"><b>{{ Number(item.price).toLocaleString('ru-RU') }} ฿</b></span>
                                <span class="catalog-params"><i class="bi bi-moon-stars-fill"></i> {{ item.bedrooms }} спал. &emsp; <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" style="width: 1.2em; fill: currentcolor;"><path d="M7 1a3 3 0 0 0-3 2.82V31h2V4a1 1 0 0 1 .88-1H18a1 1 0 0 1 1 .88V5h-5a1 1 0 0 0-1 .88V9h-3v2h19V9h-2V6a1 1 0 0 0-.88-1H21V4a3 3 0 0 0-2.82-3H7zm13 28a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM15 7h10v2H15V7z"></path></svg> {{ item.bathrooms }} сануз. &emsp; <i class="bi bi-tsunami"></i> {{ item.beach }}м</span>
                                <p>{{ item.title }}</p>
                                <div class="d-flex justify-content-end">
                                    <a href="#" v-if="$store.state.user == false" class="my-auto me-3"><span class="catalog-params" onclick="alert('Доступно только авторизированным пользователям')">В избранное</span></a>
                                    <a v-if="$store.state.user == true" class="my-auto me-3">
                                        <a href="#" class="catalog-params red_text" v-if="$store.state.user_info.favourites.includes(item.id)" @click="removeFromFavourites(item.id)">Удалить</a>
                                        <a href="#" class="catalog-params" v-else  @click="addToFavourites(item.id)">В избранное</a>
                                        
                                    </a>
                                    <a href="#" @click="offer.item = item.title" class="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target="#offerModal">Оставить заявку</a>
                                </div>
                                </div>
                            </a>
                            </div>

                        </div>
                        
                    </div>
            </div>-->
        </div>
        
    </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default{
    props: {
        id: Number
    },

    data(){
        return{
            itemInfo: [],
            offer: {
                item: ''
            },
            openImgSrc: 0
        }
    },
    methods: {
        loadData(){
            axios.get('https://samui31.com/api/get_item.php?id=' + this.$props.id).then((response)=>{
                this.itemInfo = response.data
                console.log(this.itemInfo)
            })

            if(this.$store.state.user == true){
                this.offer.name = this.$store.state.user_info.name
                this.offer.phone = this.$store.state.user_info.phone
                this.offer.mail = this.$store.state.user_info.mail
            }
        },
        addToFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'add', user_id: this.$store.state.user_info.id, item_id: id}).then(()=>{
                if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id}).then((response)=>{
                    this.$store.commit('authFavourites', response.data)
                    console.log(this.$store.state.user_info.favourites)
                })
                }
            })
        },
        removeFromFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'delete', user_id: this.$store.state.user_info.id, item_id: id}).then(()=>{
                if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id}).then((response)=>{
                    this.$store.commit('authFavourites', response.data)
                    console.log(this.$store.state.user_info.favourites)
                })
                }
            })
        },
        sendOffer(){
            this.offer.type = this.itemInfo.type
            axios.post('https://samui31.com/api/sendOffer.php', this.offer).then(()=>{
                alert('Заявка отправлена')
                window.location.reload()
            })
        },
        openImgGetSrc(index){
            document.querySelector('#itemSliderModalBid'+this.$props.id).querySelector('.active').classList.remove('active')
            document.querySelector('#itemSliderModalBid'+this.$props.id).querySelectorAll('.carousel-item')[index].classList.add('active')
            this.openImgSrc = index
            console.log(this.openImgSrc)
        }
    },
    created(){
        this.loadData()
    }
}
</script>